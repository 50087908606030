import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import '../../assets/scripts/jquery.nicescroll.min'

if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        $('body').niceScroll({
            mousescrollstep: 40,
            scrollspeed: 80,
            zindex: 101,
            cursorcolor: '#fabe00',
            cursorborder: '1px solid #fabe00',
            cursorwidth: '5px',
            cursoropacitymax: 0.6,
            smoothscroll: true,
            iframeautoresizeL: true,
        });
    }
}

//首页banner图部分swiper
var interleaveOffset = 0.5; //视差比值

var bannerswiper = new Swiper(".index-banner .swiper-container", {
    loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        waitForTransition: false,
    },
    pagination: {
        el: ".index-banner .swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><svg><circle cx="14" cy="14" r="12"></circle></svg></div>';
        },
    },
    on: {
        init: function (swiper) {
            $('.index-banner .index-banner-text .text-item').eq(0).fadeIn().siblings().fadeOut()
        },
        slideChangeTransitionStart: function () {
            $('.index-banner .index-banner-text .text-item').eq(this.realIndex).fadeIn().siblings().fadeOut()
        },
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    }
});


//首页产品部分swiper
var proswiper = new Swiper(".index-product .product-swiper .swiper", {
    autoHeight: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
    allowTouchMove: false,
});

// 产品部分效果
!(function(){
    $('.index-product .product-tabs .tab-item').eq(0).addClass('active')
    // $(".index-product .product-list li").each(function () {
    //     $(this).find('.pro-cont1 .item-img img').eq(1).fadeIn()
    //     $(this).find('.pro-cont2 .left-list .item').eq(1).addClass('active')
    // });
})()
// 产品部分效果，产品分类切换
$('.index-product .product-tabs .tab-item').on('click',function (e) {
    e.preventDefault();
    let index = $(this).index()
    $('.index-product .product-tabs .tab-item').eq(index).addClass('active').siblings().removeClass('active')
    proswiper.slideTo(index)
    if (window.devicePixelRatio < 2) {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            setTimeout(() => {
                $('body').getNiceScroll().resize()
            }, 500);
        }
    }
})
// 产品部分效果，产品角度图切换
// $(".index-product .product-list li .pro-cont2 .left-list .item").on('click',function(e){
//     e.preventDefault();
//     let index = $(this).index()
//     $(this).addClass('active').siblings().removeClass('active')
//     $(this).parents('li').find('.pro-cont1 .item-img img').eq(index).fadeIn().siblings().fadeOut()
// })

//页面滚动监听
$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var searchImgTop = $('.index-search .fixed-img').offset().top;
    var searchImgBtm = $('.index-about').offset().top;
    var aboutImgTop = $('.index-about .cont-img').offset().top;
    var aboutImgBtm = $('.index-about .cont-img').offset().top + $('.index-about .cont-img').height();

    // console.log((90 - ((aboutImgTop - scroH) / viewH) * 100))
    //搜索logo位移效果
    if (scroH > searchImgTop - viewH && scroH < searchImgBtm) {
        let trans = 85 - ((searchImgTop - scroH) / viewH) * 100
        $('.index-search .fixed-img img,.index-about .fixed-img img').css('transform', 'translateY(' + trans +'%)');
    }
    //about图片位移效果
    if (scroH > aboutImgTop - viewH*1.5 && scroH < aboutImgBtm) {
        console.log(111)
        let trans = (aboutImgTop - scroH)/10
        $('.index-about .cont-img b').css('transform', 'translateY(' + trans + 'px)');
    }
});
